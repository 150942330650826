@import '../../sass/app.scss';

#about-me {
    @include full-bleed;
    background-color: $primary-transparent;
    
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    
    padding: 64px 0;

    text-align: justify;

    img {
        max-width: 500px;
        padding: $gap-24 0;
    }

    p {
        max-width: 500px;
        align-self: end;
        padding-left: $gap-24;
        padding-right: $gap-24;
        padding-bottom: $gap-12;
    }
    
    h5 {
        font-size: 14px;
    }
                
    h4 {
        font-size: 20px;
        margin: 0;
        padding: 0;
        
        @media (max-width:  $breakpoint-mobile) {
            font-size: $font-size-18;
        }    
    }

    div {
        max-width: 500px;
        padding-right: $gap-24;
        padding-left: $gap-24;
        align-self: center;
    }

    @media (max-width:  $breakpoint-tablet) {
        @include side-padding;

        display: flex;
        flex-direction: column;

        p {
            padding: 32px 0 0 0;
            align-self: center;
        }

        div {
            text-align: left;
            padding: 64px 0 32px 0;
        }

        img {
            width: 100vw;
            align-self: center;
        }
    }
}
@import '../../sass/app.scss';

#accordion-item {

    h2 {
        margin-top: $gap-32;
    }

    .title-img {
        max-width: 100%;
        height: auto;
        border-radius: $border-radius-20;
    }
    .open {
        background-color: $primary-transparent;
        margin: 0;
    }

    .closed {
        background-color: none;
        margin: 0; 
    }

    .border-radius {
        border-radius: $border-radius-20 $border-radius-20 0 0;
    }
    
    .arrow-icon {
        width: 15px;
    }
    
    .open-card {
        display: flex;
        flex-direction: column;

        background-color: $primary-transparent;

        padding: $gap-12;
        border-radius: 0 0 $border-radius-20 $border-radius-20;
        margin-top: 0;

        > p {
            padding-top: $gap-12;
            font-weight: 600;
            text-align: center;
        }
    
        p {
            text-align: center;
        }
    
        hr {
            border: 1px solid $fontlight;
            border-radius: 20px;
        }
    }
}

@import '../../sass/app.scss';

#impressum {
    @include section-margin;
    @include side-padding;
    @include inter-section-margin;

    text-align: justify;

    h1 {
        margin-bottom: $gap-32;

        @media (max-width:  $breakpoint-mobile) {
            font-size: 24px;
        }
    }
}
.grid-wrapper {
        display: grid;
        grid-template-columns:
          1fr
          #{"min(65ch, 100%)"}
          1fr;
  }
    
.grid-wrapper > * {
      grid-column: 2;
  }
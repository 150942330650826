@import '../../sass/app.scss';

#pricing {
    @include side-padding;
    @include section-margin;

    text-align: justify;

    .pricing-info {
        text-align: center;

        p {
            &:last-child  {
                background-color: $highlight;
                text-align: justify;
                padding: 24px;
                border-radius: 20px;
            }
        }
    }

    .pricing-refund {
        @include inter-section-margin;
    }
    
    img {
        width: 80px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: $border-radius;
}
}
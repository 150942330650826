@import './sass/app.scss';

.App {
  text-align: center;
  color: $fontdark;
  font-family: $font-main;
}

h1 {
  text-transform: uppercase;
  font-size: $font-size-24;
  font-weight: 600;
  text-align: center;
  margin: $gap-12;
  margin-bottom: $gap-24;
}

h2 {
  text-transform: uppercase;
  font-size: $font-size-24;
  font-weight: 400;
  text-align: center;
}

h3 {
  font-size: $font-size-12;
  font-weight: 400;
  text-align: center;
}

h4 {
  font-family: $font-secondary;
  font-size: $font-size-18;
  font-weight: 600;

  @media (min-width:  $breakpoint-mobile) {
    font-size: 20px;
}
}

@import '../../sass/app.scss';

#footer {
    @include full-bleed;
    margin-top: 200px;
    padding-top: $gap-32 + $gap-32;

    background-color: $primary;

    img {
        max-width: 80px;
        align-self: center;
        margin-bottom: $gap-32 + $gap-32;
    }

    h4 {
        margin-bottom: $gap-32;
        font-size: $font-size-18;

        @media (min-width:  $breakpoint-mobile) {
            font-size: 20px;
        }
    }


    .disclaimer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 40px;

        a {
            text-decoration: none !important;
            color: inherit;

            &:hover {
                color: $highlight;
            }
        }

        h4 {
            font-size: $font-size-18;
            margin-left: 5px;
            margin-right: 5px;

            @media (max-width:  $breakpoint-mobile) {
                font-size: 16px;
            }
        }
    }
    .footer-address-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 32px;

        h4{
            margin: 0 25px 30px 25px;
            text-align: left;
        }
    }

}
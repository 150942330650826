@mixin side-padding {
    padding-left: $gap-24;
    padding-right: $gap-24;
}

@mixin section-margin {
    margin-bottom: 20vh;
    scroll-margin-top: 25vh;
}

@mixin inter-section-margin {
    margin-top: $gap-32 + $gap-32;
}

@mixin full-bleed {
    width: 100%;
    grid-column: 1 / 4;
}
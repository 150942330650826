@import '../../sass/app.scss';

#info {
    @include section-margin;
    @include full-bleed;
    
    h3 {
        margin-bottom: $gap-32;
        font-size: 30px;;

        @media (max-width:  $breakpoint-mobile) {
            font-size: $font-size-18;
      }
    }
    
    h4 {
        margin-top: $gap-32;
        font-size: $font-size-18;

        @media (min-width:  $breakpoint-mobile) {
              font-size: 20px;
        }
    }

    img {
        max-width: 100%;
        height: auto;
        border-radius: 0px 0px 20px 20px;
    }
    .info-section-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        margin-left: 10vw;
        margin-right: 10vw;

        @media (max-width:  $breakpoint-tablet) {
            flex-direction: column;
            align-items: center;
            margin-left: 5vw;
            margin-right: 5vw;
      }
    }
    
    .practice-info {
        width: 50%;
        @include inter-section-margin;
        margin-left: 10px;
        margin-right: 10px;

        @media (max-width:  $breakpoint-mobile) {
            margin-top: 10px;
      }

        h3 {
            font-size: 24px;
        }
        @media (max-width:  $breakpoint-mobile) {
            width: 100%;
      }
    }

    .location-title-nk {
        @media (max-width:  $breakpoint-mobile) {
            margin-top: 50px;
      } 
    }

    .infobox {
        img {
        align-self: center;
        border-radius: 20px 20px 0 0;
        }
        div {
            background-color: $highlight;
            padding: 12px 0;
        }
        h6 {
            font-size: 10px;
            text-align: right;
            background-color: $highlight;
            margin: 0;
        }
    }

    .contact-box {
        margin-top: 96px;
        background-color: $highlight;
        padding: 24px 0;
    }
}
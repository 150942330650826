// $bgcolor: rgba(224, 208, 232, 0.71);
// $fontdark:#420d61;
// $fontlight: #FFFFFF;
// $primary:rgb(171, 146, 184);
// $primary-transparent: rgba(167, 132, 188, 0.5);
// $secondary: #A784BC;
// $shadow: rgba(167, 132, 188, 1);
// $highlight: #f5e194;

$bgcolor: #f4e1ee;
$fontdark:#5E094D;
$fontlight: #FFFFFF;
$primary: #e4b5d4;
$primary-transparent: #e4b5d48a;
$secondary:  #D229BB;
$highlight:  #fdedae;


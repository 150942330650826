@import '../../sass/app.scss';

.language-btn {
    font-size: 14px;
    color: black;
    
    div {
        padding: 0;
        margin: 0;
        display: flex;
        align-self: center;
    }
    
    button {
        padding: 0px 2px 0px 2px;
        margin: 0;
        text-decoration: none !important;
        color: inherit;
        border: none;
        background-color: transparent;
        text-align: center;

        &:hover {
            color: $highlight;
        }
    }
}
@import '../../sass/app.scss';

#treatment {
    @include section-margin;

    .treatment-info {
        margin-top: $gap-32;

        p {
            &:last-child {
                background-color: $highlight;
                border-radius: 8px;
                padding: 6px 0;
            }
        }
    }

    .treatment-text {
        @include side-padding;
        @include inter-section-margin;
        text-align: justify;
    }
}
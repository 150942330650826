@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

$font-main: 'Montserrat', sans-serif;
$font-secondary: 'Poppins', sans-serif;

$border-radius: 50px;
$border-radius-20: 20px;

$gap-8: 8px;
$gap-12: 12px;
$gap-24: 24px;
$gap-32: 32px;

$font-size-24: 24px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-12: 12px;

$breakpoint-mobile: 600px;
$breakpoint-tablet: 950px;
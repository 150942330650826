@import '../../sass/app.scss';

#accordion {
    @include inter-section-margin;

    div { 
        text-align: center;
        margin: $gap-12 0;
        p {
            text-align: justify;
        }
    }
}
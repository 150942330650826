@import '../../sass/app.scss';


#nav {   
    display: flex;
    flex-direction: column;
    padding-bottom: $gap-12;
    padding-top: $gap-12;
    
    background-color: $primary;

    position: sticky;
    top: 0;
    left: 0;

    @media (min-width:  $breakpoint-mobile) {
        padding-left: 32px;
        padding-right: 32px;
    }

    .nav-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: $gap-12;
        padding-right: $gap-12;

        div {
            display: flex;
            flex-direction: row;
        }

        a {
            img {
            width: 100px;
            display: flex;
            align-items: center;
    
            transition: all 0.2s ease-in-out;
    
            @media (min-width:  $breakpoint-mobile) {
                width: 150px;
            } 
            }
            &:hover {
                    transform: scale(0.93);
            }
        }
    }

    .nav-menu-icon {
        width: 50px;
        padding-right: $gap-12;

        @media (min-width:  $breakpoint-tablet) {
          display: none;
        }
    }

    .nav-desktop-menu {
        max-width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: center;

        @media (max-width:  $breakpoint-tablet) {
            display: none;
        }
        
        a {
        text-decoration: none !important;
        color: inherit;
        margin: 0px 20px;
            
            &:hover {
            color: $highlight;
            }

            h5 {
                font-size: $font-size-18;
            }
        }
    }

    .nav-open-menu {
        padding-bottom: $gap-32;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-top: $gap-12;

        position: absolute;
        width: 100%;
        background-color: $highlight;

        a {        
            text-decoration: none !important;
            color: inherit;

            margin-top: $gap-12;
            &:first-child {
                margin-top: $gap-24;
              }
            &:last-child {
                margin-bottom: $gap-24;
            }
            &:hover {
                color: $primary;
            }
        }
        
        @media (min-width:  $breakpoint-mobile) {
           display: none;
        }
    }
}
@import '../../sass/app.scss';

.doctolib-btn {
  position:fixed;
  top: 110px; 
  z-index:10000000000; 
  right:0;
  display:block;
  text-align:center;
  opacity: 0.85;
  background-color:#0596de;
  color:#ffffff;
  font-size:14px;
  overflow:hidden;
  width:100px;
  border-radius:4px 0 0 4px;
  text-decoration:none;
  padding:10px;
  line-height:1.4;

  @media (min-width:  $breakpoint-mobile) {
    top: 280px;         
  }

  span {
    font-size:14px;
  }

  img {
    display:inline-block;
    height:15px;
    margin-top:10px;
    vertical-align:middle;
    width:auto;
  }
}

@import '../../sass/app.scss';

#privacy {
    text-align: justify;
    @include inter-section-margin;


    ol {
        padding-top: $gap-24;
        padding-right: $gap-24;
    }

    h1 {
        justify-self: center;
        padding: 0;
        margin: 0;

        @media (max-width:  $breakpoint-mobile) {
            font-size: 24px;
        }
    }
}
@import '../../sass/app.scss';

#landing {
  @include full-bleed;
  @include section-margin;
  
  color: $fontlight;  
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content:center;
  padding-bottom: 15vh;

  animation: backgroundCarousel 40s ease-in-out infinite;
  -webkit-animation: backgroundCarousel 40s ease-in-out infinite;
  -moz-animation: backgroundCarousel 40s ease-in-out infinite;
  -ms-animation: backgroundCarousel 40s ease-in-out infinite;

  background-attachment: fixed;
  background-size: cover;
  background-position: center center;

  

  @media (max-width:  $breakpoint-mobile) {
    background: url('../../images/landing2.webp');
    background-size: cover;
    background-position: center;
    animation: none;
    margin-bottom: 100px;
  }

  .landing-text {
    padding-top: $gap-32;
    padding-bottom: $gap-32;
      
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: $primary-transparent;

    h1  {
      margin-bottom: 80px;
    }
        
    @media (min-width:  $breakpoint-mobile) {
      h1 {
        font-size: 40px;
      }
      
      h2 {
        font-size: 40px;
      }
        
      h3 {
        font-size: 24px;
      }
    }
  }
}


@keyframes backgroundCarousel {
  0% {
    background-image: url('../../images/landing1.webp');
  }
  30% {
    background-image: url('../../images/landing2.webp');
  }
  60% {
    background-image: url('../../images/landing3.webp');
  }
  100% {
    background-image: url('../../images/landing1.webp');
  }
}

@-webkit-keyframes backgroundCarousel {
  0% {
    background-image: url('../../images/landing1.webp');
  }
  30% {
    background-image: url('../../images/landing2.webp');
  }
  60% {
    background-image: url('../../images/landing3.webp');
  }
  100% {
    background-image: url('../../images/landing1.webp');
  }
}

@-moz-keyframes backgroundCarousel {
  0% {
    background-image: url('../../images/landing1.webp');
  }
  30% {
    background-image: url('../../images/landing2.webp');
  }
  60% {
    background-image: url('../../images/landing3.webp');
  }
  100% {
    background-image: url('../../images/landing1.webp');
  }
}



  